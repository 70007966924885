<template>
  <div class="padding24" id="third_Party_order">
    <div class="isDisplay" v-show="$route.meta.isShow">
      <div class="top">
        <div>
          <router-link v-if="isShowAdd" to="/videoManage/freeAudio/addFreeAudio/0">
            <a-button  icon="plus" class="margin_right20" type="primary">新建</a-button>
          </router-link>
        </div>
        <div class="search-box">
          <div style="margin-right: 10px">
            日期：
            <a-range-picker :placeholder="['开始时间', '结束时间']" style="width: 250px;" @change="timeChange" />
          </div>
          <div>
            <a-input v-model="filter.title" placeholder="请输入活动名称" style="width: 200px; margin-right: 10px">
              <a-icon slot="prefix" type="search"/>
            </a-input>
            <a-button icon="search" type="primary" @click="onSearch">搜索</a-button>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"  :loading="tableLoading">
        <span slot="readingCount" slot-scope="readingCount, record, index">
          <span class="margin_right10">{{record.readingCount}}</span>
          <span style="color:#1890ff;" @click="seeClick(record.id, index)">查看</span>
        </span>
        <span slot="completeCount" slot-scope="completeCount, record, index">
          <span class="margin_right10">{{record.completeCount}}</span>
          <span style="color:#1890ff;" @click="getCompleteCount(record.id, index)">查看</span>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <router-link v-if="isShowEdit" class="margin_right10" :to="`/videoManage/freeAudio/addFreeAudio/${record.id}`">
            编辑
          </router-link>
        </span>
      </a-table>
      <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100"/>
    </div>
    <router-view />
  </div>
</template>

<script>
import {getFreeAudioListAPI, getReadingCountAPI, getCompleteCountAPI } from "@/request/api/videoManage"
import MyPagination from '@/components/pagination/MyPagination'
import { codeFn } from '@/utils/tools'
import { audioColumns } from './constans'
import moment from "moment"

export default {
  components: { MyPagination },
  created() {
    this.fetchData()
    if (codeFn("/adminv2/editFreeAudio")) this.isShowEdit = true
    if (codeFn("/adminv2/addFreeAudio")) this.isShowAdd = true
  },
  computed: {
    isShow() {
      return this.$route.meta.isShow
    }
  },
  watch: {
    isShow(val) {
      if (val) this.fetchData()
    }
  },
  data() {
    return {
      isShowAdd:false,
      isShowEdit:false,
      tableLoading: true,
      pageNo: 1,
      pageSize: 20,
      count: 0,
      columns: audioColumns,
      tableData: [],
      filter: {
        title: '',
        readStartDate: '',
        readEndDate: ''
      }
    };
  },
  methods: {
    timeChange(times) {
      this.pageNo = 1
      if (times.length > 0) {
        const start = moment(times[0]).format('yyyy-MM-DD')
        const end = moment(times[1]).format('yyyy-MM-DD')
        this.filter.readStartDate = start
        this.filter.readEndDate = end
      } else {
        this.filter.readStartDate = ''
        this.filter.readEndDate = ''
      }
      this.fetchData()
    },
    onSearch() {
      this.pageNo = 1
      this.fetchData()
    },
    // 查看听课人数
    async seeClick(val, index){
      const { code, data } = await getReadingCountAPI({ id:val})
      if (code !== 200) return
      this.tableData[index].readingCount = data
    },
    // 查看完播次数
    async getCompleteCount(val, index) {
      const { code, data } = await getCompleteCountAPI({ id:val})
      if (code !== 200) return
      this.tableData[index].completeCount = data
    },
    async fetchData() {
      this.tableLoading = true
      const params = {
        ...this.filter,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      const { data, code } = await getFreeAudioListAPI(params)
      this.tableLoading = false
      if (code !== 200) return
      this.tableData = data.lists
      this.count = data.count
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.fetchData();
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#third_Party_order")
    }
  }
}
</script>

<style lang="less" scoped>
#third_Party_order {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.top {
  display: flex;
  justify-content: space-between;
  .search-box {
    display: flex;
    justify-content: space-around;
  }
}
</style>
