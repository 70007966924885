export const audioColumns = [
  {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
      width: '30%'
  },
  {
      title: '听课人数',
      dataIndex: 'readingCount',
      key: 'readingCount',
      scopedSlots: {customRender: 'readingCount'},
  },
  {
      title: '播放次数',
      dataIndex: 'playCount',
      key: 'playCount'
  },
  {
      title: '完播次数',
      dataIndex: 'completeCount',
      key: 'completeCount',
      scopedSlots: {customRender: 'completeCount'},
  },
  {
      title: '日期',
      dataIndex: 'readDate',
      key: 'readDate',
  },
  {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
  },
  {
      title: '操作',
      fixed: 'right',
      width: 150,
      key: 'operation',
      dataIndex: 'operation',
      scopedSlots: {customRender: 'operation'},
  },
]
